<template>
  <div v-if="avantages" class="adv">
        <div v-for="a of content.avantages" class="adv__item">
            <div class="adv__item--svg">
                <Svg :icon="avantages[a.name].svg" size="5"></Svg>
            </div>
            <h4>
                {{avantages[a.name].title}}
            </h4>
            <p v-html="a.text">
            </p>
        </div>
    </div>
</template>

<script>
import Svg from '@/components/utils/Svg'
import {getData} from '@/composables/utils/getData';
import { onMounted, ref } from '@vue/runtime-core';

export default {
    components:{Svg},
    props:['content'],
    setup(){
        console.log('ini');
        const avantages = ref(null);
        onMounted(async () => {
            console.log('icic');
            const data = await getData('/advantages');
            avantages.value = generateAdvantages(data);
        })

        const generateAdvantages =(data) => {
            return {
                security:{
                    svg:"security",
                    title:data.feature.filter(el => el.svg=='security')[0].label
                },
            durable:{
                svg:"durable",
                title:data.feature.filter(el => el.svg=='durable')[0].label
            },
            economy:{
                svg:"economy",
                title:data.feature.filter(el => el.svg=='economy')[0].label

            },
            robust:{
                svg:"robust",
                title:data.feature.filter(el => el.svg=='robust')[0].label

            },
            quality:{
                svg:"droplet",
                title:data.feature.filter(el => el.svg=='droplet')[0].label

            }
            }
        }

        return {avantages}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';

.adv{
    display: flex;
    justify-content: center;

    @include respond(phone){
        flex-direction: column;
    }
    &__item{
        flex: 0 0 20%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-child){
            margin-right: 3rem;
            @include respond(phone){
                margin-right: 0;
                margin-bottom: 4rem;
            }
        }
        &--svg{
            background-color: $color-primary;
            color:$color-white;
            height: 10rem;
            width: 10rem;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        & h4{
            margin-top: 2rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1.8rem;
            color:$color-primary;
        }

        & p{
            margin-top: 2rem;
            text-align: center;
        }
    }
}

</style>