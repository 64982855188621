<template>
  <div class="target">
      <div v-for="targ of targets" class="target__item">
          <div class="target__img" :style="`background-image:url('${$store.state.api}${targ.hero.background.formats.small.url}')`">
              &nbsp;
          </div>
          <div class="target__content">
                <h4>
                    {{targ.solutionLink.title}}
                </h4>
                <p v-html="targ.solutionLink.description">
                </p>
                <router-link :to="{name:'Solutions', params:{page: targ.pageId}}" class="target__content--cta btn__link">
                    {{$store.state.language === 'fr' ? "En savoir plus" :"Read more"}} <span class="btn__link--span"> <Svg icon="arrow-link" size="2" /> </span>
                </router-link>
          </div>
        
      </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';
import { onMounted, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
export default {
    components:{
        Svg
    },
    props:['filter'],
    setup(props){
        const store = useStore();
        const targets = ref(null);

        const targetsData = ref(store.state.solutions);

        watch(() => store.state.solutions,  (n , o) => {
            targetsData.value = n;
            updateTarget();
        })

        watch(() => props.filter, (n, o) => {
            updateTarget();
        } )


        
        onMounted(() => {
            updateTarget()
        })

        const  updateTarget = () => {
            targets.value = props.filter ? targetsData.value.filter(el => el.pageId !== props.filter) : targetsData.value;
        }
        


        return {targets}
    }
}
</script>

<style lang="scss">
@import "@/style/main";

.target{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100% + 2.33%);

    @include respond(phone){
            flex-direction: column;
        }
    &__item{
        flex: 0 0 31%;
        margin-right: 2.33%;
        display: flex;
        flex-direction: column;
        box-shadow: $shadow;
        margin-bottom: 2.33%;
        background-color: $color-white;

        @include respond(phone){
            margin-right: 0;
            margin-bottom: 2rem;
        }
    }

    &__img{
        // flex: 0 0 45%;
        height: 23rem;
        background-position: center;
        background-size: cover;
    }

    &__content{
        padding: 2rem;
        display: flex;
        justify-content: center;
        
        flex-direction: column;
        
        flex: 1;
        & h4{
            // align-self: flex-start;
            font-size: 1.6rem;
            text-transform: uppercase;
            padding: 1rem 1.8rem;
            color:$color-white;
            background-color: $color-primary;
            margin-bottom: 2rem;
            // height: 7rem;
            display: flex;
            align-items: center;
        }

        & p{
            flex: 1;
            display: flex;
            flex-direction: column;
            
            text-align: justify;
            font-size: 1.4rem;
            & ul{
                margin-top: 1rem;
            }
            & li{
                @include listStyle;
            }
        }

        &--cta{
            margin-top: 2rem;
            
            align-self: flex-end;

            

            
        }



        
    }
}
</style>