import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_CMS;
// axios.defaults.withCredentials = true;
// axios.defaults.headers["Accept-Language"] = "en";
// axios.defaults.headers["Authorization"] = "Bearer " + $cookies.get("jwt");

createApp(App).use(store).use(router).mount("#app");
