<template>
  <div class="features">
      <div v-for="item of content.features" class="features__item">
          <Svg :icon="item.svg" size="6"/>
          <div class="features__content">
              <h4>
                  {{item.pretitle}} <counter-features :value="item.value"></counter-features>
              </h4>
              <h5>
                  {{item.subtitle}}
              </h5>
          </div>
      </div>
  </div>
</template>

<script>

import Svg from '@/components/utils/Svg';
import CounterFeatures from '@/components/utils/CounterFeatures.vue';

export default {
    props:['content'],
    components:{
        Svg,
        CounterFeatures
    },
}
</script>

<style lang='scss'>
@import '@/style/main';
.features{
    display: flex;
    justify-content: center;
    margin-top: 5rem;

    @include respond(phone){
        flex-direction: column;
        align-items: center;
    }
    &__item{
        display: flex;
        align-items: center;
        & svg{
            color:$color-primary;
            @include respond(phone){
                margin-bottom: 2rem;
            }
            
        }

        @include respond(phone){
            flex-direction: column;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 4rem;
            }
        }
    }

    &__content{
        margin-left: 3rem;

        @include respond(phone){
                margin-left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

        & h4{
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 5rem;
            text-transform: uppercase;
            letter-spacing: .2rem;
            line-height: 5.5rem;
            color:$color-primary;

            @include respond(phone){
                font-size: 4rem;
                line-height: 4.6rem;
            }
        }

        & h5{
            font-size: 2rem;
            font-weight: 400;

            @include respond(phone){
                font-size: 2rem
            }
        }
    }
}

</style>