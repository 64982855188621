<template>
  <div class="avantages">
    <div v-for="a of avantages" class="avantages__item">
      <div class="avantages__item--header">
        <div class="avantages__item--svg">
          <Svg :icon="a.svg" size="2.3"/>
        </div>
        <h3 class="section-subsubtitle">{{a.title}}</h3>
      </div>
      <ul class="avantages__item--feat">
        <li v-for="f of a.features" v-html="f.item"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';

export default {
  components:{
    Svg
  },
  props:['content'],
  setup(props){
    const avantages = props.content.content
    return {avantages}
  }
}
</script>

<style lang='scss'>

@import '@/style/main';

.avantages{
  display: flex;
  flex-direction: column;
  margin: 4rem 0;

  &__item{
    &:not(:last-child){
      margin-bottom: 3rem;
    }
    &--header{
      color:$color-primary;
      padding-bottom: 1rem;
      border-bottom: 2px solid currentColor;
      display: flex;
      align-items: center;
      margin-bottom: 3rem;

      & h3{
        
        margin-bottom: 0 !important;
      }
    }

    &--svg{
      width: 4rem;
      height: 4rem;
      background-color: $color-primary;
      color:$color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      margin-right: 2rem;
    }

    &--feat{
      & li{
        @include listStyle;
      }
    }
  }
}
</style>