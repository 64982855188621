import { createStore } from "vuex";

export default createStore({
  state: {
    language: "",
    languageName: "Français",
    languageChange: true,
    api: process.env.VUE_APP_CMS,
    solutions: [],
    products: [],
  },
  mutations: {},
  actions: {},
  modules: {},
});
