<template>
      <div v-if="data" class="trust">
          <div v-for="t of data" class="trust__item">
              <img :src="t.logo.formats && t.logo.formats.small ? `${$store.state.api}${t.logo.formats.small.url}` : `${$store.state.api}${t.logo.url}`" alt="">
          </div>
      </div>
</template>

<script>
import axios from 'axios';
import { onMounted, ref } from '@vue/runtime-core';

import {getData} from '@/composables/utils/getData';

export default {
    setup(){
        const data = ref(null);
        onMounted(async () => {
            data.value = await getData('/partners?type=client&display=true');
            console.log(data.value);
        })

        return {data};
    }
}
</script>

<style lang='scss'>

@import '@/style/main';

 .trust{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include respond(phone){
        flex-direction: column;
        align-items: center;
    }
    

    &__item{

        & img{
            height: 7rem;
            width: auto;
        }
        &:not(:last-child){
            margin-right: 2rem;
            @include respond(phone){
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }
    }
}

</style>