<template>
    <div v-if="faqData" :id="i" v-for="i of Object.keys(faqData)" class="faq__container">
        <template v-if="faqData[i].arr.length">
            <h3 class="section-title">
                {{faqData[i].name}}
            </h3>
            <div class="faq">
                <template  v-for="faq of faqData[i].arr">
                    <question
                        :q="faq.question"
                        :a="faq.answer">
                    </question>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import Question from '@/components/faq/Question';
import { onMounted, ref } from '@vue/runtime-core';

export default {
    props:['content'],
    components:{
        Question
    },
    setup(props){
        const faqData = ref(null);

        onMounted(() => {
            faqData.value = orderFaq(props.content);
        })

        const orderFaq = (faqs) => {
            const data = {};

            for(let cat of faqs.categories){
                data[cat.slug] = {
                    name: cat.name,
                    arr: []
                }
            }

            for(let faq of faqs.content){
                if(data[faq.theme]){
                    data[faq.theme].arr.push(faq)
                }   
            }

            return data;
        }

        return {faqData};
    }
}
</script>

<style>

</style>