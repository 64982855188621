<template>
    <div class="hero" :style="`background-image:url('${$store.state.api}${content.background.url}');`" >
        <div class="container hero__title">
             <h1 >
            {{content.header.title}}
        </h1>
            <h2>
                {{content.header.subtitle}}
            </h2>
        </div>
        <div class="hero__down">
            <button class="btn btn__round btn--primary hero__down--btn" @click="scrollTo(content.target)">
                <Svg icon="arrow-down" size="3" ></Svg>
            </button>
        </div>
       
    </div>
</template>

<script>

import scrollTo from '@/composables/utils/scroll';

import Svg from '@/components/utils/Svg';
export default {
    props:['content'],
    components:{
        Svg
    },
    setup(props){
        return {scrollTo}
    }
}
</script>

<style lang="scss">
@import '@/style/main';


.hero{
    margin-top: $nav-height + $ban-height;
    position: relative;
    
    background-size: cover;
    background-position: center;
    width: 100%;
    height: calc(100vh - #{$nav-height} - #{$ban-height});

    &__title{
        position: absolute;
       top:  8rem;
       left: 50%;
       width: 100%;
        background-color: rgba($color-primary, .9);
        padding: 3rem;
        color:$color-white;
        

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        transform: translate(-50%, 0);

        @include respond(small){
            top: 6rem;
        }
    }

    & h1{
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 3.8rem;
        margin-bottom: .5rem;

        @include respond(phone){
            font-size: 3rem;
            line-height: 3.6rem;
            margin-bottom: 1rem;
        }

        @include respond(small){
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }  
    
    & h2{
        font-weight: 400;
        text-align: center;
        @include respond(phone){
            font-size: 2rem;
        }

        @include respond(small){
            font-size: 1.8rem;
        }
    }

    &__down{
        position: absolute;
        top: 85%;
        left: 50%;

        transform: translate(-50%, -50%);

        &--btn{
            width: 6rem;
            height: 6rem;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 !important;

            @include moveDown(5);
        }
    }

}
</style>