<template>
        <div class="carousel__nav">
          <div class="carousel__nav--container">
              <button id="prev">
                  <Svg icon="arrow-left" size="2"/>
              </button>
              <div class="carousel__nav--content">
                  <span>1</span>  - <span>{{length}}</span>
              </div>
              <button id="next">
                  <Svg icon="arrow-right" size="2"/>
              </button>
          </div>
      </div>
</template>

<script>
import Svg from '@/components/utils/Svg';
export default {
    components:{
        Svg
    },
    props:['length']
}
</script>

<style>

</style>