<template>
   <section-main :data="content.header">
       <component :is="content.componentName" :content="content"></component>
        <div v-if="content.cta"  class="container__cta">
            <template v-for="j of content.cta">
                <button v-if="j.type === 'scroll'" class="container__cta--item btn" :class="`btn--${j.color}`" @click="scrollTo(j.href)">
                        {{j.label}}
                </button>
                <router-link v-else-if="j.type === 'router'" :to="{name:j.href}" class="container__cta--item btn" :class="`btn--${j.color}`">
                    {{j.label}}
                </router-link>
            </template>
        </div>
  </section-main>
</template>

<script>

import SectionMain from '@/components/utils/SectionMain';

import HomeEquation from '@/components/home/HomeEquation';
import HomeFeatures from '@/components/home/HomeFeatures';
import HomeMission from '@/components/home/HomeMission';
import FaqContent from '@/components/faq/FaqContent';
import TechnoAdvantage from '@/components/technology/TechnoAdvantage.vue';
import TechnoProcess from '@/components/technology/TechnoProcess.vue';
import TechnoServices from '@/components/technology/TechnoServices.vue';
import SolutionsAvantages from '@/components/solutions/SolutionsAvantages';
import SolutionsOffer from '@/components/solutions/SolutionsOffer';

export default {
    props:['content'],
    components:{
        FaqContent,
        HomeEquation,
        HomeFeatures,
        HomeMission,
        SectionMain,
        SolutionsAvantages,
        SolutionsOffer,
        TechnoProcess,
        TechnoServices,
        TechnoAdvantage
    },
    setup(props){
    }
}
</script>

<style>

</style>