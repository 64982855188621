<template>
  <Header v-if="data" :navigation="data.navigation"/>
  <router-view/>
  <Footer v-if="data" :social="data.footerSocial" :content="data.footerContent"/>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';

import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import { onMounted, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import {languages} from '@/composables/languages';

import {getData} from '@/composables/utils/getData';

export default {
  name: 'Home',
  components: {
    Footer,
    Header
  },
  setup(){
    const store = useStore();
    const data = ref(null);


    const langues = languages();
    onMounted(async () => {
      data.value = await getDataGeneral();

    })


    //LANGUAGE
    watch(() => store.state.language, async (n, o) => {
      store.state.languageName = langues.filter(el => el.locale === store.state.language)[0].name

      const html = document.documentElement // returns the html tag
          html.setAttribute('lang', n);

      if(!data.value || data.value.locale !== n) {
        data.value = await getDataGeneral();
      }
    })

    const getDataGeneral = async() => {
      let dataGetter = null;
        if(store.state.language){
            dataGetter = await getData('/general');
            store.state.products = await getData('/products');
            store.state.solutions = dataGetter.solutions;  
            store.state.languageChange = dataGetter.multiLingual;
            store.state.basic = dataGetter.basicContent;
        }
  
      return dataGetter;    
    }


    return {data};
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Poppins:100,300,400,700,900&display=swap');
@import '@/style/main.scss';

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
}

#app {
  font-family: Poppins, Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-grey;

  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
	padding: 0rem; /*Mets une bordure au site*/

  ::selection {
    background-color: $color-primary;
    color: $color-white;
  }

}



#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
