import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

import store from "@/store/index";

import { languages } from "@/composables/languages";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Taking care of water",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
  {
    path: "/technology/:section?/:product?",
    name: "Technology",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "techno" */ "@/views/Technology.vue"),
    meta: {
      title: "Notre technologie",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
  {
    path: "/solutions/:page?",
    name: "Solutions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "solutions" */ "@/views/Solutions.vue"),
    meta: {
      title: "Présentation de nos solutions",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    meta: {
      title: "A propos de nous",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "Faq",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ "@/views/Faq.vue"),
    meta: {
      title: "Foire aux questions",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
  {
    path: "/realisations",
    name: "Realisations",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "faq" */ "@/views/Realisations.vue"),
    meta: {
      title: "Nos réalisations",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
  {
    path: "/care4impact",
    name: "Impact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "impact" */ "@/views/Impact.vue"),
    meta: {
      title: "Care 4 Impact",
      metaTags: [
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //we set a default language
  if (!to.query.locale && !from.query.locale) {
    next({ path: to.path, query: { locale: "fr" } });
    return;
  }

  // We keep the same language between pages
  if (from.query && from.query.locale && to.query && !to.query.locale) {
    next({ path: to.path, query: { locale: from.query.locale } });
    return;
  }

  // SET LANGUAGE
  if (to.query && to.query.locale && to.query.locale !== from.query.locale) {
    const langues = languages();

    store.state.language = to.query.locale;
    store.state.languageName = langues.filter(
      (el) => el.locale === store.state.language
    )[0].name;
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = `${process.env.VUE_APP_TITLE} - ${nearestWithTitle.meta.title}`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  // ROUTE PROTECTION

  next();
});

export default router;
