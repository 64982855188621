<template>
    <section-main  :data="content.header ? content.header: header">
      <home-target :filter="page"></home-target>
  </section-main>
</template>

<script>

import HomeTarget from '@/components/home/HomeTarget';
import SectionMain from '@/components/utils/SectionMain';
import { ref, watch } from '@vue/runtime-core';

export default {
        components:{
            HomeTarget,
            SectionMain,
           
        },
        props:['content'],
        setup(props){
            const header = ref(props.content.targets)
            const page = ref(props.content.pageId);

            


            watch(() => props.content , (n, o) => {
                page.value = n.pageId;
                header.value = n.targets;
            }) 
            return {header, page}
        }
    }
</script>

<style>

</style>